import urlJoin from 'url-join';
import { OPS_API_URL } from '../../../const';
import { executeRequest } from '../../utils';

export type CheckoutData = {
    success: boolean;
    status_code: number;
    reseller?: ResellerCheckoutData;
    line_items: Variant[];
};

export type ResellerCheckoutData = {
    id: string;
    first_name: string;
    custom_name: string;
    can_ship_to_reseller: boolean;
};

export type Variant = {
    id: string;
    title: string;
    imageUrl?: string;
    quantity: number;
    price: string;
    cost: string;
    weight: number;
    dimensions?: Dimensions;
};

type Dimensions = {
    length: number;
    width: number;
    height: number;
};

type ValidateCheckoutRequest = {
    reseller_id?: string;
    line_items: LineItemValidateRequest[];
};

export type LineItemValidateRequest = {
    variant_id: string;
    quantity: number;
};

export async function validateCheckout(request: ValidateCheckoutRequest): Promise<CheckoutData> {
    return await executeRequest(urlJoin(OPS_API_URL, 'validate-checkout'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    });
}
