import * as React from 'react';

import styled from 'styled-components';
import { LinkButton } from '../components/Button';
import VendahLogo from '../components/icons/VendahLogo';
import { LogoContainer } from '../components/OrderStatusLayout';
import Title from '../components/Title';
import { LineItem, OrderContext } from '../container/Order.container';
import { Container, TitleContainer } from './styles';

const LineItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    img {
        width: 32px;
        height: 32px;
        margin-right: 16px;
    }
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 16px;
    gap: 16px;
    line-height: 14px;

    text-align: left;
`;

const NameDetailsContainer = styled(DetailsContainer)`
    background: #f9f9f9;
`;

const LineItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 16px;
`;

const TotalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 700;
    font-size: 16px;

    padding: 16px;

    background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #ffffff;
`;

function LineItemLine({ quantity, name, image }: LineItem) {
    return (
        <LineItemContainer>
            {image ? (
                <img src={image.url} alt={image.altText} />
            ) : (
                <img alt="Placeholder" src="https://via.placeholder.com/32" />
            )}
            <p>
                {quantity} × {name}
            </p>
        </LineItemContainer>
    );
}

function PendingPage() {
    return (
        <OrderContext.Consumer>
            {order => {
                const {
                    reseller,
                    customer_name,
                    total_price,
                    payment_url: paymentUrl,
                    line_items: lineItems,
                    order_name: orderName,
                    shipping_address: shippingAddress,
                    shipping_type: shippingType,
                } = order!;

                const totalPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                    Number(total_price)
                );

                let PaymentLink;
                if (paymentUrl) {
                    PaymentLink = (
                        <LinkButton color="pink" href={paymentUrl}>
                            <Title>Fazer pagamento</Title>
                        </LinkButton>
                    );
                } else {
                    PaymentLink = (
                        <LinkButton disabled>
                            Link para pagamento indisponível. Por favor tente novamente mais tarde.'
                        </LinkButton>
                    );
                }

                const LineItems = (
                    <LineItemsContainer>
                        {lineItems.map((item, index) => (
                            <LineItemLine {...item} key={index} />
                        ))}
                    </LineItemsContainer>
                );

                let ShippingContainer;
                if (shippingType === 'Entregar para cliente') {
                    ShippingContainer = (
                        <DetailsContainer>
                            <div>
                                <strong>Forma de entrega</strong>
                                <p>Entregar no meu endereço</p>
                            </div>
                            <div>
                                <strong>Endereço de entrega</strong>
                                <p>{shippingAddress.address1}</p>
                                {shippingAddress.address2 && <p>{shippingAddress.address2}</p>}
                                <p>
                                    {shippingAddress.zip}, {shippingAddress.city} - {shippingAddress.province_code}
                                </p>
                                {shippingAddress.phone && <p>Contato: {shippingAddress.phone}</p>}
                            </div>
                        </DetailsContainer>
                    );
                } else {
                    ShippingContainer = (
                        <DetailsContainer>
                            <div>
                                <strong>Forma de entrega</strong>
                                <p>Retirar com a Revendedora</p>
                            </div>
                        </DetailsContainer>
                    );
                }

                return (
                    <Container>
                        <LogoContainer>
                            <VendahLogo />
                        </LogoContainer>
                        <TitleContainer>
                            <Title>Pedido {orderName}</Title>
                        </TitleContainer>
                        {LineItems}
                        <TotalContainer>
                            <p>Total</p>
                            <p>{totalPrice}</p>
                        </TotalContainer>
                        {PaymentLink}
                        <NameDetailsContainer>
                            <div>
                                <strong>Vendido por</strong>
                                <p>{reseller.name}</p>
                            </div>
                            <div>
                                <strong>Pedido de</strong>
                                <p>{customer_name}</p>
                            </div>
                        </NameDetailsContainer>
                        {ShippingContainer}
                    </Container>
                );
            }}
        </OrderContext.Consumer>
    );
}

export default PendingPage;
